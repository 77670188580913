<template>
  <div class="container-fluid">
    <nav-bar/>
    <div class="mb-3"></div>
    <router-view></router-view>
    <footer-component/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import footerComponent from "@/components/FooterComponent";

export default {
  components: {
    NavBar,
    footerComponent
  }
}
</script>

<style>

</style>
