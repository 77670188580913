import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import App from '@/App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

const routes = [
    {path: '', component: () => import("./components/AddNew")},
    {path: '/download/', component: () => import("./components/Download")},
    {path: '/finish/', component: () => import("./components/Finish")},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
})

createApp(App).use(router).mount('#app')
