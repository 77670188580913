<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">DownloadCenter</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target='#navbarNav'>
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class='collapse navbar-collapse' id='navbarNav'>
      <ul class='navbar-nav'>
        <li class='nav-item'>
          <router-link class='nav-link' to="/">New</router-link>
        </li>
        <li class='nav-item'>
          <router-link class='nav-link' to="/download">Download</router-link>
        </li>
        <li class='nav-item'>
          <router-link class='nav-link' to="/finish">Finish</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>

</style>