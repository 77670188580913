<template>
  <div>
    ▶️ {{ numActive }} | ⏸️ {{ numWaiting }} | ⬇️ {{ downloadSpeed }} | ⬆️ {{ uploadSpeed }}
  </div>
</template>

<script>
import instance from "@/utils/axios";

export default {
  name: "FooterComponent",
  data() {
    return {
      timer: '',
      capacity: 0,
      numActive: 0,
      numWaiting: 0,
      downloadSpeed: 0,
      uploadSpeed: 0
    }
  },
  created() {
    setInterval(
        this.update, 1000
    )
  },
  methods: {
    async update() {
      let res = await instance.get('/center/getGlobalStat/')
      if (res.data.result.downloadSpeed.unit === '') {
        this.downloadSpeed = 0
      } else {
        this.downloadSpeed = res.data.result.downloadSpeed.length + res.data.result.downloadSpeed.unit + '/s'
      }
      if (res.data.result.uploadSpeed.unit === '') {
        this.uploadSpeed = 0
      } else {
        this.uploadSpeed = res.data.result.uploadSpeed.length + res.data.result.uploadSpeed.unit + '/s'
      }
      // this.capacity = res.data.result.capacity
      this.numActive = res.data.result.numActive
      this.numWaiting = res.data.result.numWaiting
    }
  }
}
</script>

<style scoped>
div {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
}
</style>